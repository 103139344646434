<template>
  <SectionSplit class="PublicBondIssue2024 reverse" src="../../assets/landing-finances/2.jpg">
    <h3>
      {{ $t('title') }}
    </h3>
    <p v-for="(paragraph, index) in $t('paragraphs')" :key="index">
      {{ paragraph }}
    </p>
  </SectionSplit>
</template>

<script>
import SectionSplit from 'views/components/SectionSplit'

export default {
  components: { SectionSplit },
}
</script>

<style lang="scss">
.PublicBondIssue2024 {
  .body {
    .Wrap > h3 {
      color: $danger;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "title": "Bond distribution complete",
    "paragraphs": [
      "Sun Investment Group (SIG), an international solar energy project development group of companies, is starting a public bond issue worth EUR 8 million. The bond distribution will last between the 4th – 22nd November, 2024. The funds raised will be used for the development of new projects, working capital, and refinancing of the existing bond issue.",
      "The bonds are secured by the first lien of project companies (SPVs) developing a portfolio of ~380 MW of solar power plants in Italy. The current estimated value of this pledge is over 22 M EUR.",
      "Sun Investment Group started its activities in 2017 and currently unites more than 100 professionals in Lithuania, Poland, Italy, and Spain. SIG has a portfolio of more than 2.7 GW, of which almost 300 MW are ready to build projects, and has a track record of more than 200 MW of already developed, constructed, and sold operating projects in Lithuania, Poland and UK."
    ]
  },
  "lt": {
    "title": "Obligacijų platinimas baigtas",
    "paragraphs": [
      "Tarptautinė saulės energetikos projektų vystymo įmonių grupė „Sun Investment Group“ (SIG) pradeda platinti viešą obligacijų emisiją. Pasitelkiant šią finansavimo priemonę planuojama pritraukti 5 mln. eurų investicijų. Platinimo etapas pradedamas 2024 m. lapkričio 4 d. ir tęsis iki lapkričio 22 dienos. Pritrauktos lėšos bus skirtos naujų projektų vystymui, apyvartiniam kapitalui ir esamos obligacijų emisijos refinansavimui.",
      "Obligacijos yra užtikrintos projektinių (SPV) įmonių, plėtojančių ~380 MW saulės jėgainių portfelį Italijoje, pirminiu įkeitimu. Šio įkeitimo dabartinė apskaičiuota vertė viršija 22 mln. Eur.",
      "„Sun Investment Group“ (SIG) yra patyrusi atsinaujinančių energijos išteklių projektų vystymo įmonė. Savo veiklą pradėjusi 2017 metais, SIG šiuo metu vienija daugiau nei 100 profesionalų Lietuvoje, Lenkijoje, Italijoje bei Ispanijoje. SIG portfelį sudaro daugiau nei 2,7 GW iš kurių beveik 300 MW galios sudaro statybai paruošti (RTB) projektai. Iki šiol SIG sėkmingai išvystė, pastatė ir pardavė daugiau nei 200 MW saulės elektrinių Lietuvoje, Lenkijoje ir JK."
    ]
  }
}
</i18n>
